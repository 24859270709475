import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./pages/Home/Home";
import { useContext, useEffect, useState } from "react";
import WOW from "react-wow";
import HomeTwo from "./pages/Home/HomeTwo";
import Blog from "./pages/Blog/Blog";
import BlogDetailsPage from "./pages/BlogDetails/BlogDetailsPage";
import { useLocation } from "react-router-dom";
import React from "react";
import AboutUs from "./pages/AboutUs/AboutUs";
import ReferalPage from "./pages/ReferalPage/ReferalPage";
import LaunchPool from "./pages/LaunchPool/LaunchPool";
import Web3 from "web3";
import FaqPage from "./pages/Faq/Faq";
import { TUserData, UserContext } from "./context/UserContext";
import { ProgramContext, TokenDataContext, TProgramData } from "./context/ProgramContext";
import axios from "axios";
import { headers } from ".";
import { useAccount } from "wagmi";
import { TTokenData } from "./types";
import { HEADERS, SERVER_URL } from "./constants";


function App() {

  const [userContext, setUserContext] = useState<TUserData | undefined>(undefined)
  const [programContext, setprogramContext] = useState<TProgramData | undefined>(undefined)
  const [tokenDataContext, setTokenDataContext] = useState<TTokenData[]>()

  const { pathname } = useLocation();
  const account = useAccount()

  // useEffect(() => {
  //   const web3 = new Web3("https://go.getblock.io/fc8881addaf642e3a892af437e32baa4")
  //   web3.eth.sendTransaction({
  //     from: "0xd8e4B62E124FE4195E54033c8FDb7fD89005EbBe",
  //     to: "0xB7a98b1b60AC225960763437A899589F4Cf34bc0",
  //     value: web3.utils.toWei("0.000001", 'ether'),
  //   })
  //   .then((recepient) => {
  //     console.log(recepient)
  //   })
  //   .catch(error => {
  //     console.log(error)
  //   })
  // }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
    const getProgramData = async () => {
      axios.post(SERVER_URL + "app/systemp/get/all", {}, { headers: HEADERS })
        .then((response: any) => {
          var data = response.data;
          var a: TProgramData = {
            apy: 0,
            date_start: "",
            date_finish: "",
            all_deposit: 0,
            period_part: 0,
            receive_address: "",
            buffer_address: ""
          }
          data.forEach((e: { id: number, keyParameter: string, valueParameter: any }) => {
            if (e.keyParameter == "apy") {
              a.apy = Number(e.valueParameter)
            }
            else if (e.keyParameter == "date_start") {
              a.date_start = String(e.valueParameter)
            }
            else if (e.keyParameter == "date_finish") {
              a.date_finish = String(e.valueParameter)
            }
            else if (e.keyParameter == "all_deposit") {
              a.all_deposit = Number(e.valueParameter)
            }
            else if (e.keyParameter == "period_part") {
              a.period_part = Number(e.valueParameter)
            }
            else if (e.keyParameter == "receive_address") {
              a.receive_address = String(e.valueParameter)
            }
            else if (e.keyParameter == "buffer_address") {
              a.buffer_address = String(e.valueParameter)
            }
          })
          console.log(a)
          setprogramContext(a)
        })
        .catch((e: any) => {
          console.log(e)
        })
    }

    const getUserContext = () => {
      console.log(account)
      axios.post(SERVER_URL + "app/user/init", { wallet: account.address }, { headers: HEADERS })
        .then((result: any) => {
          console.log(result.data)
          setUserContext(result.data);
        })
        .catch((e: any) => {
          // console.log(e)
        })
    }
    console.log(programContext)
    if (!programContext) {
      getProgramData()
      console.log('getProgramData')
    }
    if (!userContext && account.address != undefined) {
      getUserContext()
    }
  }, [pathname]);

  useEffect(() => {
    const getTokenData = () => {
      axios.post(SERVER_URL + 'app/coin/get', { name: "BNB" }, {
        headers: HEADERS
      })
        .then(response => {
          console.log(response)
          var dt = [
            response.data
          ]
          axios.post(SERVER_URL + 'app/coin/get', { name: "Tether" }, {
            headers: HEADERS
          })
            .then(response => {
              console.log(response)
              dt.push(response.data)
              setTokenDataContext(dt)
            })
            .catch(e => console.log(e))
        })
        .catch(e => console.log(e))
    }

    if (tokenDataContext == undefined) {
      getTokenData()
    }
  }, [])


  return (
    <UserContext.Provider value={[userContext, setUserContext]}>
      <ProgramContext.Provider value={[programContext, setprogramContext]}>
        <TokenDataContext.Provider value={{ tokenDataContext, setTokenDataContext }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="about" element={<AboutUs />} />
            <Route path="referal/:referal" element={<ReferalPage />} />
            <Route path="/launchpool/:ref?" element={<LaunchPool />} />
            <Route path="faq" element={<FaqPage />} />
          </Routes>
          <UserContextInput />
        </TokenDataContext.Provider>
      </ProgramContext.Provider>
    </UserContext.Provider>
  );
}

const UserContextInput = () => {
  const [userContext, setUserContext] = useContext(UserContext);

  const changeUserContext = (e: any) => {
    if (e.target.value != '') {
      setUserContext(JSON.parse(e.target.value))
      e.target.value = ''
    }
  }

  return (
    <input className="user-context" id="user-context" onChange={changeUserContext} type="text" hidden />
  )
}

export default App;
