import Faq from "../../components/Faq/Faq";
import LayoutFaq from "../../layouts/LayoutFaq"



const FaqPage = () => {
    return (
        <LayoutFaq>
            <main>
                <Faq />
            </main>
        </LayoutFaq>
    )
}

export default FaqPage;
