import axios from "axios";
import { headers } from "..";
import { useAccount } from "wagmi";
import { useContext } from "react";
import { HEADERS, SERVER_URL } from "../constants";


const handleURIconnect = (uri: any) => {
    console.log(uri)
    // const account = useAccount()
    var data = {
        walletAddress: uri.accounts[0].toString(),
    }
    axios.post(SERVER_URL + "app/user/init", data, { headers: HEADERS })
        .then((result: any) => {
            // (document.querySelector("#user-context") as HTMLInputElement).value = result.data; 
            window.location.reload()
        })
        .catch((e: any) => {console.log(e)})
    
}

export const handleURIdisconnect = (uri: any) => {
    console.log(uri)
    console.log('disconnect')
    window.location.reload()
}

export default handleURIconnect;
