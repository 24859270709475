import { ReactElement, JSXElementConstructor, ReactNode, ReactPortal } from "react";
import PageLoader from "../components/PageLoader/PageLoader"
import cn from "classnames";
import HeaderOne from "../components/Header/HeaderOne";
import FooterOne from "../components/Footer/FooterOne";


const LayoutAboutUs = (props: { children: ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; }) => {
    return (
        <div className={cn("")}>
            <PageLoader />
            <HeaderOne />
            {props.children}
            <FooterOne />
        </div>
    )
}

export default LayoutAboutUs;