import { useAccount, useConnect } from "wagmi";
import BannerSecond from "../../components/Banner/BannerSecond";
import ReferalBlock from "../../components/ReferalBody/ReferalBlock";
import StakingBody from "../../components/StakingBody/StakingBody";
import LayoutLaunchPool from "../../layouts/LayoutLaunchPool"
import { useParams } from "react-router-dom";
import { handleClickScroll } from "../../lib/helpers";


const LaunchPool = () => {
    const account = useAccount()
    const { ref } = useParams()
    console.log("REF: " + ref)
    const openReferalHowItWorks = () => {
        handleClickScroll("referal-section");
    }
    if (ref !== undefined) {
        openReferalHowItWorks()
    }
    return (
        <LayoutLaunchPool>
            <main>
                <BannerSecond />
                <StakingBody />
                {/* {account.isConnected?<ReferalBlock />: ''}                 */}
            </main>
        </LayoutLaunchPool>
    )
}


export default LaunchPool;