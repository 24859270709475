import React, { useContext, useState } from "react";
import shape01 from "../../assets/img/styled/planet-first.png";
import shape02 from "../../assets/img/styled/planet-second.png";
import shape03 from "../../assets/img/styled/planet-third.png";
import fireIcon from "../../assets/img/styled/fire-logo.png";
import CountDownOne from "../CountDown/CountDownOne";
import { ProgramContext } from "../../context/ProgramContext";

const Banner = () => {
  const [targetPrcent, setTargetPrcent] = useState<number>(0)
  const [programContext, setprogramContext] = useContext(ProgramContext);
  if (programContext && targetPrcent == 0) {
    setTargetPrcent(Number(parseFloat(String(programContext.all_deposit*100/1000000)).toFixed(2)))
  }
  
  return (
    <section className="banner-area banner-bg">
      <div className="banner-shape-wrap">
        <img src={shape01} alt="" className="img-one" />
        <img src={shape02} alt="" className="img-two" />
        <img src={shape03} alt="" className="img-three" />
      </div>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="banner-content text-center">
              {/* <img src={fireIcon} alt="" /> */}
              <h2 className="title">
              Token on New Layer 1 Blockchain <span>Magic Eden</span>
              </h2>
            </div>
            <div className="banner-progress-wrap">
              {/* <ul>
                <li>Pre Sell</li>
                <li>Soft Cap</li>
                <li>Bonus</li>
              </ul> */}
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: targetPrcent+"%" }}
                  aria-valuenow={targetPrcent}
                  aria-valuemin={0}
                  aria-valuemax={100}
                ></div>
              </div>
              <h4 className="title">
                {targetPrcent}% target raised <span>100 000 000 MAGIC</span>
              </h4>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <div className="banner-countdown-wrap text-center">
              <h2 className="title">Launchpool Will End In..</h2>

              <CountDownOne />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
