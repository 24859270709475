import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "./assets/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/css/fontawesome-all.min.css";
import "./assets/css/react-odometer-theme.css";
import "./assets/css/default.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";

import "bootstrap/dist/js/bootstrap.bundle.min";
import Web3Provider from "./components/Web3Provider";
import { UserContext } from "./context/UserContext";

export var headers = {
  "authentication": process.env.REACT_APP_AUTHORIZATION,
  'Content-Type': 'application/json',
  "Access-Control-Allow-Origin": "*"
} 


const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <Web3Provider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Web3Provider>
);
