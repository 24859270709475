import React from "react";
import shape01 from "../../assets/img/styled/planet-first.png";
import shape02 from "../../assets/img/styled/planet-second.png";
import shape03 from "../../assets/img/styled/planet-third.png";
import fireIcon from "../../assets/img/styled/fire-logo.png";
import CountDownOne from "../CountDown/CountDownOne";

const BannerSecond = () => {
  return (
    <section className="banner-area banner-bg">
      <div className="banner-shape-wrap">
        <img src={shape01} alt="" className="img-one" />
        <img src={shape02} alt="" className="img-two" />
        <img src={shape03} alt="" className="img-three" />
      </div>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="banner-content text-center">
              {/* <img src={fireIcon} alt="" /> */}
              <h2 className="title">
                Token on New Layer 1 Blockchain <span>Magic Eden</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerSecond;
