import React from "react";
import img01 from "../../assets/img/styled/about_img_big.png";
import img02 from "../../assets/img/styled/about_mini_planet.png";
import { Link } from "react-router-dom";

const WhoWeAre = () => {
  return (
    <section id="about" className="about-area pt-130 pb-130">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-img wow fadeInLeft" data-wow-delay=".2s">
              <img src={img01} alt="" />
              <img src={img02} alt="" className="img-two" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content wow fadeInRight" data-wow-delay=".2s">
              <div className="section-title mb-30">
                <span className="sub-title">Who we are</span>
                <h2 className="title">
                  Unique <span>Magic Eden</span> Platform That Offers Rewards
                </h2>
              </div>
              <p>
                We offer a unique service that will help you earn without having funds thanks to the referral program
              </p>
              <Link to="/launchpool" className="btn">
                Stake Now
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhoWeAre;
