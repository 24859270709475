import React from "react";
import chartImg from "../../assets/img/styled/chart.png";
import SalesTabButton from "./SalesTabButton";
import SalesTabContent from "./SalesTabContent";
import { Link } from "react-router-dom";

const Sales = () => {
  const chart_info_list = [
    "Contingency: 70%",
    "Business Development: 10%",
    "Investor: 30%",
    "Poland",
    "Legal & Regulation:10%",
    "Czech Republic",
  ];

  return (
    <section id="sales" className="chart-area chart-bg ">
      <div className="container">
        <div className="chart-inner">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 col-md-10 order-2">
              <div className="chart-wrap wow fadeInRight about-top-text" data-wow-delay=".2s">
                <h3>Why do millions of people around the world choose us? <Link className="color-base" to={"https://magiceden.io/about/"} target="_blank">Read</Link></h3>
                <h3>We're the talk of the crypto community. <Link className="color-base" to={"https://coinmarketcap.com/currencies/magiceden/"} target="_blank">Read</Link></h3>
                {/* <img src={chartImg} alt="" />
                <ul>
                  {chart_info_list.map((x, index) => (
                    <li key={index}>{x}</li>
                  ))} */}
                {/* </ul> */}
              </div>
            </div>

            <div className="col-lg-6 col-md-10 mb-4">
              <div
                className="chart-content wow fadeInLeft"
                data-wow-delay=".2s"
              >
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <SalesTabButton
                    title="We're already NFT giant"
                    className="active"
                    id="funding-tab"
                    target="#funding"
                    ariaControls="funding"
                    ariaSelected={true}
                  />
{/* 
                  <SalesTabButton
                    title="Token Allocation"
                    className=""
                    id="token-tab"
                    target="#token"
                    ariaControls="token"
                    ariaSelected={false}
                  /> */}
                </ul>

                <div className="tab-content" id="myTabContent">
                  <SalesTabContent
                    className={"show active"}
                    id="funding"
                    ariaLabel="funding-tab"
                    title="Magic Eden is crypto innovations driving progress"
                    description=""
                    link="/launchpool"
                  />

                  {/* <SalesTabContent
                    className={""}
                    id="token"
                    ariaLabel="token-tab"
                    title="2 CNL = 0.0967 BTC"
                    description=" The World’s 1st ICO Platform That Offers Rewards and The
                    platform helps investors to make easy to purchase and
                    sell their tokens"
                    link="/"
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};


export const Sales2 = () => {
  const chart_info_list = [
    "Magic Eden Company: 47%",
    "Binance: 7%",
    "Crypto Comunity: 8%",
    "Investment Organization: 19%",
    "Partners: 19%",
  ];

  return (
    <section id="sales" className="chart-area chart-bg ">
      <div className="container">
        <div className="chart-inner">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 col-md-10 order-0 order-lg-2">
              <div className="chart-wrap wow fadeInRight" data-wow-delay=".2s">
                <img src={chartImg} alt="" />
                <ul>
                  {chart_info_list.map((x, index) => (
                    <li key={index}>{x}</li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="col-lg-6 col-md-10">
              <div
                className="chart-content wow fadeInLeft"
                data-wow-delay=".2s"
              >
                <ul className="nav nav-tabs" id="myTab2" role="tablist">
                  <SalesTabButton
                    title="Token Allocation"
                    className="active"
                    id="funding-tab2"
                    target="#funding2"
                    ariaControls="funding2"
                    ariaSelected={true}
                  />
                </ul>

                <div className="tab-content" id="myTabContent2">
                  <SalesTabContent
                    className={"show active"}
                    id="funding2"
                    ariaLabel="funding-tab"
                    title="1 MAGIC = 0.0147 BNB"
                    description=" The predicted cost of MAGIC coins is calculated taking into account the investments attracted to the project at the moment."
                    link="/launchpool"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Sales;
