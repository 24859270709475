import { ReactElement, JSXElementConstructor, ReactNode, ReactPortal } from "react";


const ModalLayout = (props: { children: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; }) => {
    
    const closeModal = () => {
        (document.querySelector('body') as HTMLElement).classList.remove('modal-open')
    }
    
    return (
        <div className="modal-layout">
            <div className="modal-layout-wrapper">
                <div className="modal-body">
                    <div className="modal-body-wrapper">
                        <div className="modal-layout-background" onClick={closeModal}></div>
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalLayout;