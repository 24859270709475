import React, { useState, useContext } from "react";
import Countdown from "react-countdown";
import { ProgramContext } from "../../context/ProgramContext";

const CountDownOne = () => {
  const [endTime, setEndTime] = useState<number>(new Date("2024-09-30").getTime())
  const [dateNow, setDateNow] = useState<number>(new Date().getTime())
  const [programContext, setprogramContext] = useContext(ProgramContext);

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }: { days: any, hours: any, minutes: any, seconds: any, completed: any }) => {
    if (completed) {
      return (
        <>
          <div className="time-count day">
            <span>{"00"}</span>Days
          </div>
          <div className="time-count hour">
            <span>{"00"}</span>hour
          </div>
          <div className="time-count min">
            <span>{"00"}</span>minute
          </div>
          <div className="time-count sec">
            <span>{"00"}</span>second
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="time-count day">
            <span>{days < 10 ? "0" + days : days}</span>Days
          </div>
          <div className="time-count hour">
            <span>{hours < 10 ? "0" + hours : hours}</span>hour
          </div>
          <div className="time-count min">
            <span>{minutes < 10 ? "0" + minutes : minutes}</span>minute
          </div>
          <div className="time-count sec">
            <span>{seconds < 10 ? "0" + seconds : seconds}</span>second
          </div>
        </>
      );
    }
  };

  return (
    <div className="coming-time">
      {programContext? <Countdown date={new Date(programContext.date_finish).getTime()} renderer={renderer} />: ''}
    </div>
  );
};

export default CountDownOne;
