import React from "react";
import { createContext, Dispatch, SetStateAction } from "react";
import { TTokenData } from '../types'

export type TProgramData = {
    apy: number,
    date_start: string,
    date_finish: string,
    all_deposit: number,
    period_part: number,
    receive_address: any,
    buffer_address: any
}

export const ProgramContext = createContext<[TProgramData|undefined, Dispatch<SetStateAction<TProgramData|undefined>>]>([undefined, () => {}]);


export const TokenDataContext = React.createContext<{tokenDataContext: TTokenData[]|undefined, setTokenDataContext: React.Dispatch<React.SetStateAction<TTokenData[] | undefined>>}>({
    tokenDataContext: undefined,
    setTokenDataContext: () => {}
})