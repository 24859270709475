import { useContext, useEffect, useState } from "react";
import { handleClickScroll } from "../../lib/helpers"
import { useTranslation } from 'react-i18next';
import { BaseError, useAccount, useConnect, useSendTransaction, useWaitForTransactionReceipt, useBalance, useWriteContract, createConfig } from "wagmi";
import { http, parseEther, parseGwei, parseUnits } from "viem";
import { UserContext } from "../../context/UserContext";
import { ProgramContext, TokenDataContext } from "../../context/ProgramContext";
import { eth, Web3 } from 'web3'
import axios from "axios";
import { headers } from "../..";
import { on } from "events";
import { mainnet, sepolia } from "viem/chains";
import BnbBep20 from './bnb_bsc.png'
import UsdtBep20 from './usdt2.png'
import { TTokenData } from "../../types";
import { HEADERS, SERVER_URL } from "../../constants";


function makeid(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

export const abi = [
    {
        type: 'function',
        name: 'approve',
        stateMutability: 'nonpayable',
        inputs: [
            { name: 'spender', type: 'address' },
            { name: 'amount', type: 'uint256' },
        ],
        outputs: [{ type: 'bool' }],
    },
    {
        type: 'function',
        name: 'transferFrom',
        stateMutability: 'nonpayable',
        inputs: [
            { name: 'sender', type: 'address' },
            { name: 'recipient', type: 'address' },
            { name: 'amount', type: 'uint256' },
        ],
        outputs: [{ type: 'bool' }],
    },
    {
        type: "function",
        name: "transfer",
        inputs: [
            // { indexed: true, name: "from", type: "address" },
            { indexed: true, name: "recipient", type: "address" },
            { indexed: true, name: "amount", type: "uint256" }
        ],
        outputs: [{ type: "bool" }],
        payable: false,
        stateMutability: "nonpayable"
    }
] as const


const StakingBody = () => {

    const { t, i18n } = useTranslation()
    const account = useAccount();
    const connect = useConnect();
    const { data: hash, isPending, error, sendTransaction } = useSendTransaction()
    const { isLoading: isConfirming, isSuccess: isConfirmed } =
        useWaitForTransactionReceipt({
            hash,
        })
    const { data: hash2, isPending: isPending2, error: error2, writeContract } = useWriteContract()
    const { isLoading: isConfirming2, isSuccess: isConfirmed2 } =
        useWaitForTransactionReceipt({
            hash: hash2,
        })
    const [userContext, setUserContext] = useContext(UserContext);
    const [programContext, setprogramContext] = useContext(ProgramContext);
    const [stakeAmount, setStakeAmount] = useState<'' | number>('')
    const minimumAmount = 0
    const [stakeError, setStakeError] = useState<string | undefined>(undefined)
    const { tokenDataContext, setTokenDataContext } = useContext(TokenDataContext)
    const [selectedToken, setSelectedToken] = useState<TTokenData>()
    const balance = useBalance({ address: account?.address });
    const tokenBalance = useBalance({ address: account?.address, token: selectedToken?.address });
    const [buttonLoading, setButtonLoading] = useState<boolean>(false)
    const [userDeposite, setUserDeposite] = useState<string>()
    const [userReward, setUserReward] = useState<string>()

    useEffect(() => {
        if (selectedToken === undefined && tokenDataContext) {
            setSelectedToken(tokenDataContext[0])
        }
    }, [tokenDataContext])

    useEffect(() => {
        if (!userContext && account) {
            axios.post(SERVER_URL + "app/user/init", { wallet: account.address }, { headers: HEADERS })
              .then((result: any) => {
                console.log(result.data)
                setUserContext(result.data);
              })
              .catch((e: any) => {
              })
        }
    }, [])

    useEffect(() => {
        if (userContext && selectedToken) {
            var deposite = 0
            var reward = 0
            userContext.transactions.forEach(tr => {
                if (tr.meCoin.id == selectedToken.id) {
                    deposite += Number(tr.textDeposit)
                    reward += Number(tr.textReward)
                }
            })
            setUserDeposite((+deposite).toFixed(7).replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/,'$1'))
            setUserReward((+reward).toFixed(7).replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/,'$1'))
        }
    }, [userContext, selectedToken])

    if (isConfirmed == true) {
        console.log("CONFIRMED")
        axios.post(SERVER_URL + "app/transaction/save", { hash: hash, userWallet: account.address, deposit: stakeAmount, coinName: selectedToken?.name }, { headers: HEADERS })
            .then((response: any) => {
                console.log(response.data)
                window.location.reload()
            })
            .catch((e: any) => { console.log(e) })
    }
    
    if (isConfirmed2 == true) {
        console.log("CONFIRMED2")
        axios.post(SERVER_URL + "app/transaction/send", { user: true, addressFrom: account.address, userAddress: account.address, amount: stakeAmount }, { headers: HEADERS })
            .then((response: any) => {
                console.log(response.data)
                window.location.reload()
            })
            .catch((e: any) => { console.log(e) })
    }

    const stakeFunc = () => {
        console.log("Balance: ")
        console.log(balance.data?.formatted)
        console.log(stakeAmount)
        if (selectedToken?.address == "0x0000000000000000000000000000000000000000") {
            if (stakeAmount === '' || Number(stakeAmount) > Number(balance.data?.formatted) || Number(stakeAmount) < Number(minimumAmount)) {
                console.log('ERROR')
                setStakeError("Insuffience balance")
            }
            else {
                sendTransaction({
                    to: programContext?.receive_address,
                    value: parseEther(stakeAmount.toString()),
                    data: "0x"
                })
            }
        }
        else {
            if (stakeAmount === '' || Number(stakeAmount) > Number(tokenBalance.data?.formatted) || Number(stakeAmount) < Number(minimumAmount)) {
                console.log('ERROR')
                setStakeError("Insuffience balance")
            }
            else {
                writeContract({
                    address: selectedToken?.address ? selectedToken.address : "0x55d398326f99059fF775485246999027B3197955",
                    abi,
                    functionName: "approve",
                    args: [
                        programContext?.buffer_address,
                        BigInt("1158472395435294898592384258348512586931256")
                    ],
                });
            }
        }
    }

    const maxAmount = () => {
        if (!isPending) {
            if (selectedToken?.address == "0x0000000000000000000000000000000000000000") {
                if (Number(balance?.data?.formatted) < 0.00005) {
                    setStakeAmount(0)
                }
                else {
                    setStakeAmount(Number(Number(balance?.data?.formatted) - 0.00005))
                }
            }
            else {
                if (Number(tokenBalance?.data?.formatted) < 0.00005) {
                    setStakeAmount(0)
                }
                else {
                    setStakeAmount(Number(Number(tokenBalance?.data?.formatted) - 0.00005))
                }
            }
        }
    }

    const onStakeAmountInput = (e: any) => {
        if (!isPending) {
            setStakeAmount(e.target.value)
            if (e.target.value != '') {
                if (selectedToken?.address == "0x0000000000000000000000000000000000000000") {
                    if (Number(e.target.value) > Number(balance.data?.formatted)) { //Number(Web3.utils.fromWei(balance.data.value, "ether"))
                        setStakeError("Insuffience balance")
                    }
                    else if (Number(e.target.value) < Number(minimumAmount)) {
                        setStakeError(`Minimum amount: ${minimumAmount}`)
                    }
                    else {
                        setStakeError(undefined)
                    }
                }
                else {
                    if (Number(e.target.value) > Number(tokenBalance.data?.formatted)) { //Number(Web3.utils.fromWei(balance.data.value, "ether"))
                        setStakeError("Insuffience balance")
                    }
                    else if (Number(e.target.value) < Number(minimumAmount)) {
                        setStakeError(`Minimum amount: ${minimumAmount}`)
                    }
                    else {
                        setStakeError(undefined)
                    }
                }
            }
            else { setStakeError(undefined) }
        }
    }
    const [totalValue, setTotalValue] = useState<any>(undefined)
    if (totalValue == undefined && programContext !== undefined) {
        var a = programContext.all_deposit * 582.112;
        setTotalValue(a.toLocaleString("en-us", { minimumFractionDigits: 0 }))
    }
    // const [submitDisabled, setSubmitDisabled] = useState<boolean>(isActiveSubmit())

    return (
        <section className={`staking-area ${account.isConnected ? '' : 'mb-4'}`}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="staking-items mt-4 mt-md-0">
                            <div className="card no-hover staking-card p-4 mb-2">
                                {tokenDataContext?.map(tk => (
                                    <div className="staking-item-crypto" onClick={() => { if (isConfirmed || isConfirmed2 || isPending || isPending2) { } else { setSelectedToken(tk); setStakeAmount(''); setStakeError(undefined) } }}>
                                        <img src={tk.logoUrl} alt="" />
                                        <h3 className={`${tk.id == selectedToken?.id ? "selected" : ""}`}>{tk.symbol}</h3>
                                    </div>
                                ))}
                            </div>
                            {/* <div className="card no-hover staking-card mb-4">
                                <h3 className="m-0 color-secondary">${totalValue ? totalValue : 0}</h3>
                                <p>Total Value Locked</p>
                            </div>
                            <div className="card no-hover staking-card mb-4">
                                <h3 className="m-0 color-secondary">97.23%</h3>
                                <p>Annual return</p>
                            </div> */}
                            {/* <div className="card no-hover staking-card">
                                <h3 className="m-0">6997</h3>
                                <p>Number of Stakers</p>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="card no-hover staking-card single-staking mb-4">
                            <h3 className="m-0">Participate Stake</h3>
                            <span className="balance "><span className="color-secondary">{userContext ? userDeposite : 0}</span> {selectedToken?.symbol}</span>
                            <span className="">Reward: <span className="color-secondary">{userContext ? userReward : 0}</span> {selectedToken?.symbol}</span>
                            <div className="mt-md-3">
                                <div className="">
                                    <div>
                                        <div className="justify-content-between d-flex info-box">
                                            <div className="flex-column d-flex info-right">
                                                <span className="color-secondary">{programContext ? programContext.apy : 0}%</span>
                                                <span>APY*</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="input-box my-4">
                                <div className="input-area d-flex flex-column flex-md-row">
                                    <div className="input-text">
                                        <input type="number" placeholder="0.00" value={stakeAmount} onChange={onStakeAmountInput} />
                                        <button onClick={maxAmount}>Max</button>
                                    </div>
                                    <button disabled={isPending || isPending2 || isConfirming || isConfirming2 || stakeError != undefined} onClick={account?.isConnected ? stakeFunc : () => { (document.querySelector("#open-connect-modal") as HTMLElement).click() }} className="btn input-btn mt-2 mt-md-0 ml-md-3 d-flex aling-items-center justify-content-center">{isPending || isPending2 ? 'Confirming...' : isConfirming || isConfirming2 ? "Confirmation..." : 'Stake'}</button>
                                </div>
                                {stakeError != undefined ? <span className="text-error">{stakeError}</span> : balance && balance.data ? <span className="text-thirdText">Balance: {selectedToken?.address === "0x0000000000000000000000000000000000000000" ? balance.data?.formatted : tokenBalance.data?.formatted} {selectedToken?.symbol}</span> : <span className="text-thirdText">Balance: 0 {selectedToken?.symbol}</span>}
                                {error && (
                                    <div className="text-error">Error: {(error as BaseError).shortMessage || error.message}</div>
                                )}
                            </div>
                            {/* <span>
                                Once staked, you need to register for every IDO, so we can calculate the guaranteed allocation. Once registered, we lock your tokens, but you still can participate in other IDOs.
                            </span>
                            <span className="mt-3">
                                <strong>*APY is dynamic.</strong>
                            </span> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default StakingBody
