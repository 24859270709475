import { QueryClientProvider } from "@tanstack/react-query";
import { ReactNode } from "react";
import { useConnect, WagmiProvider } from "wagmi";
import queryClient, { wagmiConfig } from "./wagmi";


export default function Web3Provider({ children }: { children: ReactNode }) {
    return (
        <WagmiProvider config={wagmiConfig}>
            <QueryClientProvider client={queryClient}>
                {children}
            </QueryClientProvider>
        </WagmiProvider>
    )
}