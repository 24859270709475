import { useParams } from "react-router-dom";
import BannerSecond from "../../components/Banner/BannerSecond";
import ReferalBody from "../../components/ReferalBody/ReferalBody";
import LayoutReferalPage from "../../layouts/LayoutReferalPage"
import { useEffect, useState } from "react";


const ReferalPage = () => {
    const { referal } = useParams()
    const [completed, setCompleted] = useState<boolean>(false)
    useEffect(() => {
        if (!completed && referal) {
            window.sessionStorage.setItem("referralLink", referal)
            window.location.href = "/launchpool"
        }
    })
    return (
        <div></div>
    )
}

export default ReferalPage;