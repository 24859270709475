import { createContext, Dispatch, SetStateAction } from "react";
import { TTokenData } from "../types";


export type TChildUser = {
    walletAddress: string,
    deposit: number
}

export type TReferalGrid = {
    id: number,
    minPeople: number,
    maxPeople: number,
    airdrop: number,
    referralStakePercent: number,
    maxDeposit: number
}

export type TUserData = {
    id: number,
    wallet: string,
    dateCreated: Date,
    transactions: TTransaction[],
}

export type TTransaction = {
    id: number,
    hash: string,
    reward: number,
    deposit: number,
    meCoin: TTokenData,
    textDeposit: string
    textReward: string
}

export const UserContext = createContext<[TUserData|undefined, Dispatch<SetStateAction<TUserData|undefined>>]>([undefined, () => {}]);
