import React from "react";
import cn from "classnames";

const ContactOneInfoItem = (props: { item: { icon: string | number | boolean | cn.ArgumentArray | cn.Mapping | null | undefined; content: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; }; }) => {
  return (
    <div className="contact-info-item">
      <div className="icon">
        <span className="icon-background"></span>
        <i className={cn("fas", props.item.icon)}></i>
      </div>
      <div className="content">
        <p>{props.item.content}</p>
      </div>
    </div>
  );
};

export default ContactOneInfoItem;
