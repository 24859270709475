import { MetaMaskAvatar } from 'react-metamask-avatar';

const AccountBlock = ({ address }: { address: string|undefined }) => {
    if (address) {
        var semiAddressList = address.split('')
        var semiAddress = ''
        for (var i = 0; i < semiAddressList.length; i++) {
            if (i < 4) {
                semiAddress += semiAddressList[i]
            }
            else if (i == 4) {
                semiAddress += '...'
            }
            else if (i > 37) {
                semiAddress += semiAddressList[i]
            }        
        }
        return (
            <div className='connected-wallet-container'>
                <MetaMaskAvatar address={address} size={24} />
                <div className="referal-list-address">{semiAddress}</div>
            </div>
        )
    }
    return (
        <div>{address}</div>
    )
}

export default AccountBlock;