import { createWeb3Modal } from '@web3modal/wagmi/react'
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'

import { UseConnectReturnType, WagmiProvider, ResolvedRegister, useConfig } from 'wagmi'
import { bscTestnet, bsc } from 'wagmi/chains'
import { QueryClient } from '@tanstack/react-query'
import { createContext, useContext } from 'react'
import handleURIconnect, { handleURIdisconnect } from '../../handlers/walletConnectHandlers'
const queryClient = new QueryClient()
const projectId = 'b3da2c70610f6d4043b65d75d48224fa'
// 2. Create wagmiConfig
const metadata = {
    name: 'Magic Eden',
    description: 'Magic Eden launchpool',
    url: 'https://magicedenpool.com', // origin must match your domain & subdomain
    icons: ["https://magiceden.io/img/favicon/favicon.ico"]
}

const chains = [bsc] as const
export const wagmiConfig = defaultWagmiConfig({
    chains,
    projectId,
    metadata,
})
// wagmiConfig.connectors[0].emitter.on("connect", handleURIconnect)
// // wagmiConfig.connectors[0].emitter.on("disconnect", handleURIdisconnect)
// wagmiConfig.connectors[0].emitter.on("disconnect", handleURIdisconnect)
console.log(wagmiConfig.connectors)
wagmiConfig.connectors.forEach((e: any) => {
    e.emitter.on("connect", handleURIconnect);
    e.emitter.on("disconnect", handleURIdisconnect);
})

// 3. Create modal
createWeb3Modal({
    metadata,
    wagmiConfig: wagmiConfig,
    projectId,
    enableAnalytics: true // Optional - defaults to your Cloud configuration
})


export default queryClient;