import ModalLayout from "../ModalLayout"
import shape01 from "../../assets/img/styled/planet-first.png";
import shape02 from "../../assets/img/styled/planet-second.png";
import shape03 from "../../assets/img/styled/planet-third.png";

const ReferalHowItWorksModal = () => {

    const closeModal = () => {
        (document.querySelector('body') as HTMLElement).classList.remove('modal-open')
    }

    return (
        <ModalLayout>
            <div className="referal-how-it-works-modal">
                <div className="card banner-bg">
                    <div className="banner-shape-wrap">
                        <img src={shape01} alt="" className="img-one" />
                        <img src={shape02} alt="" className="img-two" />
                        <img src={shape03} alt="" className="img-three" />
                    </div>
                    <div className="referal-how-it-works-modal-wrapper row">
                        <h3>Referral bonus program? <span className="close-modal-btn" onClick={closeModal}></span></h3>
                        <div className="referal-text-block mb-4">
                            <h4>This referral program will allow you to earn an incredible amount</h4>
                            <ul className="list-unstyled">
                                <li>1. Authorize with your wallet</li>
                                <li>2. The launchpool page will have your referral link at the bottom</li>
                                <li>3. Click on the link and it will be copied</li>
                                <li>4. Share your referral link and get bonuses</li>
                            </ul>
                        </div>
                        <h3 className="">BONUS GRID</h3>
                        <div className="referal-text-block">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Friend</th>
                                        <th>Airdrop</th>
                                        <th>Max deposite</th>
                                        <th>Ref APY</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1-3</td>
                                        <td>1.05</td>
                                        <td>1300000 Magic Eden</td>
                                        <td>5%</td>
                                    </tr>
                                    <tr>
                                        <td>4-6</td>
                                        <td>1.1</td>
                                        <td>1800000 Magic Eden</td>
                                        <td>8%</td>
                                    </tr>
                                    <tr>
                                        <td>7+</td>
                                        <td>1.2</td>
                                        <td>2200000 Magic Eden</td>
                                        <td>13%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </ModalLayout>
    )
}

export default ReferalHowItWorksModal;