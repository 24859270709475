import ContactOne from "../../components/Contact/ContactOne";
import Sales, { Sales2 } from "../../components/Sales/Sales";
import LayoutAboutUs from "../../layouts/LayoutAboutUs"
import TopPartners2 from "../../components/TopPartners/TopPartners2";
import TopPartners3 from "../../components/TopPartners/TopPartners3";
import WhyChooseUs from "../../components/WhyChooseUs/WhyChooseUs";


const AboutUs = () => {
    return (
        <LayoutAboutUs>
            <main className="fix">
                <Sales />
                <WhyChooseUs />
                <Sales2 />
                <ContactOne />
            </main>
        </LayoutAboutUs>
    )
}

export default AboutUs;