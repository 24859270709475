import React from "react";
import { Link, To } from "react-router-dom";
import cn from "classnames";

const SalesTabContent = (props: { className: string | number | boolean | cn.ArgumentArray | cn.Mapping | null | undefined; id: string | undefined; ariaLabel: string | undefined; title: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; description: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; link: To; }) => {
  return (
    <div
      className={cn("tab-pane fade", props.className)}
      id={props.id}
      role="tabpanel"
      aria-labelledby={props.ariaLabel}
    >
      <div className="chart-content-inner">
        <h2 className="title">{props.title}</h2>
        <p>{props.description}</p>
        <Link to={props.link} className="btn color-black">
          Start Now
        </Link>
      </div>
    </div>
  );
};

export default SalesTabContent;
